<!-- <div *ngIf="2 >= statusMain" class="exp-tag text-size-12" style="text-transform:none">{{statusTAG}}</div> -->

<div
  [ngClass]="{'estimates p-1':statusMain == 2,'appExpCan':((statusMain == 3 || statusMain == 4 || statusMain == 1) && estimateDashboardItem.logo != null),'estExpCan':((statusMain == 3 || statusMain == 4) && estimateDashboardItem.logo === null)}">

  <!-- <div *ngIf="statusMain > 2" class="exp-tag text-size-12" style="background-color: #666666;text-transform: none;">
 {{statusTAG}}</div> -->

  <div *ngIf="statusMain == 2 || statusMain == 1" class="dropdown is-hoverable is-right cancel mr-3">
    <!-- <div class="dropdown-trigger">
 <button class="button" style="border: none;" aria-haspopup="true" aria-controls="dropdown-menu">
 <span class="icon">
 <img src="../../../assets/svg/ellipsis-solid.svg">
 </span>
 </button>
 </div> -->
    <div class="dropdown-menu drop-down-action">
      <div class="dropdown-content" *ngIf="statusMain == 1">
        <a class="dropdown-item" (click)="editBooking(estimateDashboardItem)">
          {{'PROFILE.EDIT' | translate}}
        </a>
      </div>

      <div class="dropdown-content" *ngIf="statusMain == 2">
        <a class="dropdown-item" (click)="openCancelPopup('')">
          {{'DASHBOARD.CANCEL' | translate}}
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="column is-9" *ngIf="statusMain == 1">
    <div class="columns">

      <div class="column is-3 garage-logo">
        <div *ngIf="estimateDashboardItem.logo != null" class="is-full-mobile mt-3">
          <img src="{{estimateDashboardItem.logo}}">
        </div>
        <div class="msg-icon-mobile is-hidden-tablet is-clickable" (click)="messagePopup()">
          <div>
            <img class=" icon" src="../../../assets/svg/msg.svg" alt="">
            <div *ngIf="estimateDashboardItem.notification_count > 0" class="field-button is-flex">
              <div class="notification py-1 px-0 is-flex is-justify-content-center is-align-items-center">
                <div class="has-text-weight-bold is-size-7">{{estimateDashboardItem.notification_count > 100 ? 99 : estimateDashboardItem.notification_count}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

            <div class="column is-9">
                <div class="msg-icon is-hidden-mobile is-clickable" (click)="messagePopup()">
                    <div >
                        <img class="is-clickable icon" src="../../../assets/svg/msg.svg" alt="" >
                    <div *ngIf="estimateDashboardItem.notification_count > 0" class="field-button is-flex">
                        <div class="notification py-1 px-0 is-flex is-justify-content-center is-align-items-center" >
                          <div class="has-text-weight-bold is-size-7">{{estimateDashboardItem.notification_count > 100 ? 99 : estimateDashboardItem.notification_count}}</div>
                        </div>
                    </div>
                    </div>

                </div>
                <div class="mb-1">
                    <div class="">
                        <h4>{{estimateDashboardItem.garage[0].name}}</h4>
                    </div>
                </div>

        <div class="mb-3">
          <div class="">
            <p>{{estimateDashboardItem.garage[0].address1}}, {{estimateDashboardItem.garage[0].address2}},
              {{estimateDashboardItem.garage[0].city}}, {{estimateDashboardItem.garage[0].state}},
              {{estimateDashboardItem.garage[0].country}}
            </p>
          </div>
        </div>
        <div class="mt-2 mb-2 view-direction">
          <img class="garage-facility mr-3" src="assets/svg/view-direction.svg" />
          <a class="color-arabama-green"
            href="https://www.google.com/maps/dir/?api=1&amp;origin='{{estimateDashboardItem.zip_code}}'&destination={{destination}}"
            target="_blank"> View Directions
          </a>
        </div>
        <div class="mt-2 mb-5 view-direction">({{estimateDashboardItem.rating.cum_rating | number: '1.0-1'}}/
          {{estimateDashboardItem.rating.totalRating}})
        </div>
        <div class="features-wrapper columns is-multiline pl-4">
          <div class="features-inner column is-narrow mb-2 p-0 pr-1 "
            *ngFor="let item of estimateDashboardItem.amenities; index as i">
            <div [ngClass]="i !=0 ? ' ' : ''" class="p-0 d-flex is-align-items-center">
              <img *ngIf="item.amenities_id === 5" src="assets/svg/car.svg"/>
              <img *ngIf="item.amenities_id === 1" src="assets/svg/free_wifi.svg"/>
              <img class="garage-facility" *ngIf="item.amenities_id === 1" src="assets/svg/wifi.svg" />
              <img class="garage-facility" *ngIf="item.amenities_id === 2" src="assets/svg/seat.svg" />
              <img class="garage-facility" *ngIf="item.amenities_id === 3" src="assets/svg/car.svg" />
              <img class="garage-facility" *ngIf="item.amenities_id === 4" src="assets/svg/car.svg" />
              <img class="garage-facility state-control" *ngIf="item.amenities_id === 5"
                src="assets/svg/state-ins.svg" />
              <img class="garage-facility" *ngIf="item.amenities_id === 6" src="assets/svg/seat.svg" />
              <img class="garage-facility" *ngIf="item.amenities_id === 7" src="assets/svg/car.svg" />
              <div class="">
                <p class="text-size-12 pr-3 pl-3 line-height">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="column"></div>
        </div>
        <div class="vehicle-details px-4 is-full-mobile mt-3">
          <div class="estimate-details">
            <div class="esti-vechile">
              <div class="ml-4 mb-5">
                <h4>{{'DASHBOARD.VEHICLE' | translate}}</h4>
                <p class="mb-4">{{estimateDashboardItem.vehcileName}}</p>
              </div>
            </div>
            <div class="esti-services mb-5">
              <div class="ml-4">
                <h4>{{'DASHBOARD.SERVICES' | translate}}</h4>
                <ul *ngFor="let item of estimateDashboardItem.items;index as i">
                  <li class="mb-1">{{item.name}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns upcoming-app-details px-5">
      <div class="esti-vechile">
        <div class="ml-4 mb-5">
          <h4>{{'DASHBOARD.VEHICLE' | translate}}</h4>
          <p class="mb-4">{{estimateDashboardItem.vehcileName}}</p>
        </div>
      </div>
      <div class="esti-services mb-5">
        <div class="ml-4">
          <h4>{{'DASHBOARD.SERVICES' | translate}}</h4>
          <ul *ngFor="let item of estimateDashboardItem.items;index as i">
            <li class="mb-1">{{item.name}}</li>
          </ul>
        </div>
      </div>
      <div class="app-premium mb-3">
        <div class="ml-4">
          <h4>Estimation Price</h4>
          <p class="has-text-weight-bold text-size-24">{{estimateDashboardItem.total}}</p>
        </div>
      </div>

    </div>
  </div> -->
  <!-- <div class="column is-3 has-text-centered is-flex is-flex-direction-column is-justify-content-center"
    *ngIf="statusMain == 1">

    <div class="has-text-centered is-flex is-align-items-center is-flex-direction-column">
      <div class="is-hidden" *ngIf="statusMain == 1">
        <p class="has-text-weight-bold text-size-22">200</p>
      </div>
      <div class="appoint-wrapper mb-5">
        <div class="mt-2" *ngIf="statusMain == 1">
          <div class="up-appointment mb-4">
            <div>
              <h4 class="has-text-left line-height mb-1">Randevu</h4>
              <p class="has-text-left line-height">{{estimateDashboardItem.updateDate}}</p>
            </div>
          </div>
          <div class="up-timing">
            <div>
              <h4 class="has-text-left line-height mb-1">Zaman</h4>
              <p class="has-text-left line-height">{{estimateDashboardItem.updateTime}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100">
      <button class="button-secondary mb-2" (click)="editBooking()">Reschedule</button>
      <button class="button-secondary mb-2" (click)="viewQuotation()">View Quotations</button>
      <button class="button-secondary" (click)="cancelEstimate()">Cancel</button>
    </div>
  </div> -->

  <!-- <h2 class="mb-5 text-size-20" *ngIf="statusMain > 1">{{displayInfo}}</h2> -->
  <div *ngIf="statusMain" class="dashboardAccordian">
    <mat-expansion-panel [expanded]="estimatecurrIndex === 0 ? estimateindex === 0 : estimatecurrIndex === estimateDashboardItem.id"
      (click)="onExpandEstimationCollapse(estimateDashboardItem.id)">
      <mat-expansion-panel-header>
        <mat-panel-title class="is-flex is-justify-content-space-between">
          <h2 class="is-flex-tablet" *ngIf="estimateDashboardItem.status =='booked' && statusMain == 1"><p class="expiry-tag mr-2">{{'DASHBOARD.BOOKED_ON' | translate}}</p> <p class="pt-1">{{estimateDashboardItem.updateDate}} - {{estimateDashboardItem?.vehicle_details[0]?.make_name}} {{estimateDashboardItem?.vehicle_details[0]?.model_name}}</p></h2>
          <h2 class="is-flex-tablet" *ngIf="estimateDashboardItem.status =='confirmed' && statusMain == 1"><p class="expiry-tag mr-2">{{'DASHBOARD.BOOKED_ON' | translate}}</p> <p class="pt-1">{{estimateDashboardItem.updateDate}} - {{estimateDashboardItem?.vehicle_details[0]?.make_name}} {{estimateDashboardItem?.vehicle_details[0]?.model_name}}</p></h2>
          <h2 class="is-flex-tablet" *ngIf="statusMain == 2"><p class="expiry-tag mr-2">{{'DASHBOARD.ESTIMATION_ON' | translate}}</p> <p class="pt-1">{{estimateDashboardItem.estimate_date}} - {{estimateDashboardItem?.vehicle_details[0]?.make_name}} {{estimateDashboardItem?.vehicle_details[0]?.model_name}}</p></h2>
          <h2 class="is-flex-tablet" *ngIf="statusMain == 3"><p class="cancel-tag mr-2">{{'DASHBOARD.CANCELLED_ON' | translate}}</p> <p class="pt-1">{{estimateDashboardItem.cancelledFormatDate}} - {{estimateDashboardItem?.vehicle_details[0]?.make_name}} {{estimateDashboardItem?.vehicle_details[0]?.model_name}}</p></h2>
          <h2 class="is-flex-tablet" *ngIf="statusMain == 4">
            <p class="cancel-tag mr-2">{{'DASHBOARD.EXPIRED_ON' | translate}}</p>
            <p *ngIf="estimateDashboardItem.updated_by !== 'garage'" class="pt-1">{{estimateDashboardItem.expiredFormatDate}} - {{estimateDashboardItem?.vehicle_details[0]?.make_name}} {{estimateDashboardItem?.vehicle_details[0]?.model_name}}</p>
            <p *ngIf="estimateDashboardItem.updated_by === 'garage'" class="pt-1">{{estimateDashboardItem.dateUpdate}} - {{estimateDashboardItem?.vehicle_details[0]?.make_name}} {{estimateDashboardItem?.vehicle_details[0]?.model_name}}</p>
          </h2>
          <div class="column is-3-desktop is-4-tablet px-0 is-flex is-justify-content-center" [ngClass]="isMobile? 'is-justify-content-end': ''" *ngIf="statusMain == 2" >
            <div class="is-flex is-align-items-center has-text-weight-semibold">
              <div class="garage_count mr-2">{{estimateDashboardItem.garage_count}} </div>
              <div *ngIf="!isMobile">{{'DASHBOARD.OFFER_HAS_ARRIVED' | translate}} </div>
            </div>
          </div>
          <div class="column is-3-desktop is-4-tablet px-0 is-flex is-justify-content-center" [ngClass]="isMobile? 'is-justify-content-end': ''" *ngIf="estimateDashboardItem.status =='confirmed' && statusMain == 1" >
            <div class="is-flex is-align-items-center">            
              <img class="conf-img mr-2" src="../../../assets/svg/appointment-confirmed.svg" alt="">
            </div>
            <p *ngIf="!isMobile" class="has-text-weight-semibold is-flex is-align-items-center">{{'DASHBOARD.APPROVED' | translate}} </p>
          </div>
          <div class="column is-3-desktop is-4-tablet px-0 is-flex is-justify-content-center" [ngClass]="isMobile? 'is-justify-content-end': ''" *ngIf="estimateDashboardItem.status =='booked' && statusMain == 1" >
            <div class="is-flex is-align-items-center">
              <img class="book-img mr-2" src="../../../assets/svg/appointment-booked.svg" alt="">
            </div>
            <p *ngIf="!isMobile" class="has-text-weight-semibold is-flex is-align-items-center">{{'DASHBOARD.WAITING_FOR_APPROVAL' | translate}} </p>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mobile-appointment mb-3" *ngIf="statusMain != 2">
      <div class="column is-flex is-align-items-center is-justify-content-center" *ngIf="statusMain == 4">
        <img class="icon" src="../../../assets/svg/date-icon.svg" alt="" >
        <div *ngIf="!estimateDashboardItem.appointment1" class="p-2">
            <h4 class="has-text-left line-height mb-1">Expired date</h4>
            <p class="has-text-left line-height">
              {{estimateDashboardItem.expiredFormatDate}}
            </p>
          </div>
          <div *ngIf="estimateDashboardItem.appointment1 && estimateDashboardItem.updated_by !== 'garage'" class="p-2">
            <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.BOOKED_ON' | translate}}</h4>
            <p class="has-text-left line-height pb-1">
              {{estimateDashboardItem.appointmentDate}}
            </p>
            <p class="has-text-left line-height pb-1">
              {{estimateDashboardItem.appointmentTime}}
            </p>
          </div>
          <div *ngIf="estimateDashboardItem.appointment1 && estimateDashboardItem.updated_by == 'garage'" class="p-2">
            <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.APPOINTMENT_TIME_PASSED' | translate}}</h4>
            <p class="has-text-left line-height pb-1">
              {{estimateDashboardItem.appointmentDate}}
            </p>
            <p class="has-text-left line-height pb-1">
              {{estimateDashboardItem.appointmentTime}}
            </p>
          </div>
      </div>
      <div class="column is-flex is-align-items-center is-justify-content-center" *ngIf="statusMain == 3">
        <img class="icon" src="../../../assets/svg/date-icon.svg" alt="" >
          <div *ngIf="!estimateDashboardItem.appointment1" class="p-2">
            <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.CANCELLED' | translate}}</h4>
            <p class="has-text-left line-height">
              {{estimateDashboardItem.cancelledFormatDate}}
            </p>
          </div>
          <div *ngIf="estimateDashboardItem.appointment1" class="p-2">
            <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.BOOKED_ON' | translate}}</h4>
            <p class="has-text-left line-height pb-1">
              {{estimateDashboardItem.appointmentDate}}
            </p>
            <p class="has-text-left line-height pb-1">
              {{estimateDashboardItem.appointmentTime}}
            </p>
          </div>
      </div>
      <div class="columns m-0 is-flex is-justify-content-space-between" *ngIf="statusMain == 1">
        <div class="column is-6 pr-0">
          <div class="is-flex is-align-items-center is-justify-content-center">
            <img class="icon" src="../../../assets/svg/date-icon.svg" alt="" >
            <div class="py-2 pl-2">
              <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.BOOKED_ON' | translate}}</h4>
              <p class="has-text-left line-height">
                {{estimateDashboardItem.updateDate}}
              </p>
            </div>
          </div>
        </div>
        <div class="column is-6 pl-0">
          <div  class="is-flex is-align-items-center is-justify-content-center">
            <img class="icon" src="../../../assets/svg/clock.svg" alt="" >
            <div class="py-2 pl-2">
              <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.TIME' | translate}}</h4>
              <p class="has-text-left line-height">{{estimateDashboardItem.updateTime}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="columns m-0" style="position: relative">
        <!-- <div *ngIf="statusMain == 2" class="dropdown is-hoverable is-right cancel mr-3">
          <div class="dropdown-trigger">
            <button class="button" style="border: none;" aria-haspopup="true" aria-controls="dropdown-menu">
              <span class="icon">
                <img src="../../../assets/svg/ellipsis-solid.svg">
              </span>
            </button>
          </div>
          <div class="dropdown-menu drop-down-action"> -->
            <!-- <div class="dropdown-content" *ngIf="statusMain == 1">
              <a class="dropdown-item" (click)="editBooking()">
                {{'PROFILE.EDIT' | translate}}
              </a>
            </div> -->
<!-- 
            <div class="dropdown-content" *ngIf="statusMain == 2">
              <a class="dropdown-item cancel-btn" (click)="openCancelPopup('1')">
                {{'DASHBOARD.CANCEL' | translate}}
              </a>
            </div>
          </div>
        </div> -->
     

        <div class="column p-0">
          <!-- <h2 class="expiry-tag" *ngIf="estimateDashboardItem.status =='booked' && statusMain == 1">{{'DASHBOARD.BOOKED' | translate}}</h2>
          <h2 class="expiry-tag" *ngIf="estimateDashboardItem.status =='confirmed' && statusMain == 1">{{'DASHBOARD.CONFIRMED' | translate}}</h2>
          <h2 class="expiry-tag" *ngIf="statusMain == 3">{{'DASHBOARD.CANCELLED' | translate}}</h2>
          <h2 class="expiry-tag" *ngIf="statusMain == 4">{{'DASHBOARD.EXPIRED' | translate}}</h2> -->
          <div class="columns app-card m-0">

            <div *ngIf="estimateDashboardItem.logo != null" class="column is-narrow py-0 pl-0 garage-logo garage-img">
              <div class="profile-field">
                <!-- <img class="logo-img" src="{{estimateDashboardItem.logo}}"> -->
                  <div class="profile-img">
                    <img src="{{estimateDashboardItem.logo}}">
                  </div> 
              </div>
            </div>

            <div class="column is-9-tablet is-10-desktop pl-0 pt-0 garage-detail" [ngClass]="estimateDashboardItem.logo !== null? 'is-9-mobile':'is-12-mobile'">
              <div *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null" class="">
                <div class="is-flex">
                  <h4 class="pr-2">{{estimateDashboardItem.garage[0].name}}</h4>
                  <div *ngIf="statusMain == 1">
                      <div class="msg-icon is-clickable" (click)="messagePopup()">
                          <img class="is-clickable icon" src="../../../assets/svg/msg.svg" alt="" style="width: 20px; height: 20px;">
                      <div *ngIf="estimateDashboardItem.notification_count > 0" class="field-button is-flex">
                          <div class="notification py-1 px-0 is-flex is-justify-content-center is-align-items-center" >
                            <div class="has-text-weight-bold is-size-7">{{estimateDashboardItem.notification_count > 100 ? 99 : estimateDashboardItem.notification_count}}</div>
                          </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>

              <div *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null" class="mb-1">
                <div class="">
                  <p>{{estimateDashboardItem.garage[0].address1}},
                    {{estimateDashboardItem.garage[0].address2}},
                    {{estimateDashboardItem.garage[0].city}},
                    {{estimateDashboardItem.garage[0].state}},
                    {{estimateDashboardItem.garage[0].country}}
                  </p>
                </div>
              </div>
              <div *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null" class="view-direction">
                <img class="image is-24x24" src="assets/svg/view-direction.svg" style="width: 20px; height: 20px;"/>
                <a class="color-arabama-green pl-2"
                  href="https://www.google.com/maps/dir/?api=1&amp;origin='{{estimateDashboardItem.zip_code}}'&destination={{destination}}"
                  target="_blank">{{'DASHBOARD.VIEW_DIRECTIONS' | translate}}
                </a>
              </div>
              <!-- <div *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null && estimateDashboardItem?.rating?.totalRating" class="view-direction">
                ({{estimateDashboardItem?.rating?.cum_rating | number:
                '1.0-1'}}/ {{estimateDashboardItem?.rating?.totalRating}})</div> -->
                <div class="star-rating" *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null && estimateDashboardItem?.rating?.totalRating">
                  <star-rating [value]="estimateDashboardItem?.rating?.cum_rating" [totalstars]="5" checkedcolor="#AAD043"
                    uncheckedcolor="#c7c6c6" size="19px" [readonly]="true"></star-rating>
                  <span *ngIf="estimateDashboardItem?.rating.totalRating" class="rating-count text-size-13">( {{estimateDashboardItem?.rating.cum_rating | number: '1.0-1'}}/{{estimateDashboardItem?.rating.totalRating}} )</span>
                </div>
                <!-- <div class="mt-2 mb-5 view-direction">({{estimateDashboardItem.rating.cum_rating | number: '1.0-1'}}/
                  {{estimateDashboardItem.rating.totalRating}})
                </div> -->
              <div class="features-wrapper columns is-multiline pt-1 pl-3 my-0 mr-0 desktop-amenities">
                <div class="features-inner pb-1"
                  *ngFor="let item of estimateDashboardItem?.amenities?.slice(0, amenitiesLength); index as i">
                  <div [ngClass]="i !=0 ? ' ' : ''" class="p-0 d-flex is-align-items-center">
                    <img class="image is-24x24 garage-facility"  src="{{item.logo_url}}" />
                    <div>
                      <p class="text-size-14 pl-1 pt-1 line-height">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
                <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateDashboardItem?.amenities?.length" (click)="amenitiesLength = estimateDashboardItem?.amenities?.length">+{{estimateDashboardItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
                <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.amenities?.length === amenitiesLength && estimateDashboardItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
              </div>

              <div class="features-wrapper columns is-multiline pt-1 pl-3 my-0 mr-0 desktop-amenities">
                <div class="features-inner pb-1"
                  *ngFor="let item of estimateDashboardItem?.specialization?.slice(0, specializationLength); index as i">
                  <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                      <p class="text-size-13 px-2 py-1">{{ item.name }}</p>
                  </div>
                </div>
                <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.specialization?.length > defaultSpecializationCount && specializationLength !== estimateDashboardItem?.specialization?.length" (click)="specializationLength = estimateDashboardItem?.specialization?.length">+{{estimateDashboardItem?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
                <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.specialization?.length === specializationLength && estimateDashboardItem?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}</div>
              </div>

              <div class="vehicle-details is-full-mobile">
                <div class="estimate-details">
                  <div class="esti-vechile is-flex">
                    <div class="image is-flex is-justify-content-center is-align-items-center" >
                      <img src="/assets/svg/veh-info.svg" alt="">
                    </div>
                    <div class="ml-4 py-2">
                      <h4>{{'DASHBOARD.VEHICLE' | translate}}</h4>
                      <p>{{estimateDashboardItem.vehcileName}}</p>
                    </div>
                  </div>
                  <div class="esti-services is-flex">
                    <div class="image is-flex is-justify-content-center is-align-items-center" >
                      <img src="/assets/svg/service_detail.svg" alt="">
                    </div>
                    <div class="ml-4 py-2">
                      <h4>{{'DASHBOARD.SERVICES' | translate}}</h4>
                      <ul *ngFor="let item of estimateDashboardItem.items; let i = index">
                        <li style="cursor: pointer;" (click)="toggleDetails(i,item)" class="mb-0 pb-1">{{i+1}}. {{item.name}}
                          <img *ngIf="selectedItemIndex !== i && (item.selected_answer || item.notes)" class="pl-1" src="../../../assets/svg/VectorUp.svg" alt="" width="12px" height="12px">
                          <img *ngIf="selectedItemIndex === i && (item.selected_answer || item.notes)" class="pl-1" src="../../../assets/svg/Vectordown.svg" alt="" width="12px" height="12px">
                        </li>
                        
                        <div *ngIf="selectedItemIndex === i" class="pl-4 detaileddata">
                          <div *ngFor="let ans of Data" class="is-flex">
                            <i class="fa fa-solid fa-circle custom-icon"></i>
                            <div class="pl-2">{{ans.relevant_answer}} <span *ngIf="ans.relevant_answer">-</span> {{ans.selected_answer}}</div>
                          </div>
                          <div *ngIf="item.notes" class="pb-1" >
                            <!-- <p style="color: #aad043; font-weight: bold;">Comments:</p> -->
                            <div *ngIf="item.notes.length < 25" class="comments">
                                {{item.notes}}
                            </div>
                            <div *ngIf="item.notes.length > 25 && item.showMore" class="comments">
                                {{item.notes.slice(0, 25)}} <span class="is-clickable more" (click)="item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                            </div>
                            <div *ngIf="item.notes.length > 25 && !item.showMore" class="comments">
                                {{item.notes}} <span class="is-clickable more" (click)="item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                            </div>
                        </div>
                        </div>
                        
                      </ul>
                      </div>
                    </div>
                  <div *ngIf="statusMain == 2" class="esti-location is-flex">
                    <div class="image is-flex is-justify-content-center is-align-items-center" >
                      <img class="icon is-medium" src="../../../assets/svg/location-final.svg" alt="">
                    </div>
                    <div class="ml-4 py-2">
                      <h4 >{{'HOME.LOCATION' | translate}}</h4>
                      <p style="word-break: break-all;">{{estimateDashboardItem.zip_code}}</p>
                    </div>
                  </div>
                  <div *ngIf="statusMain == 2" class="date is-flex py-2">
                      <div class="image is-flex is-justify-content-center is-align-items-center" >
                        <img src="/assets/svg/date-icon.svg" alt="">
                      </div>
                      <div class="is-flex">
                        <div class="mx-4">
                          <h4>{{'DASHBOARD.CREATED_DATE' | translate}}</h4>
                          <p>{{estimateDashboardItem.estimate_date}}</p>
                        </div>
                        <div class="ml-4">
                          <h4>{{'DASHBOARD.EXPIRY_DATE' | translate}}</h4>
                          <p>{{estimateDashboardItem.estimate_expiry}}</p>
                        </div>
                      </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="features-wrapper columns is-multiline pl-3 my-0 mobile-amenities">
            <div class="features-inner pb-1"
              *ngFor="let item of estimateDashboardItem?.amenities?.slice(0, amenitiesLength); index as i">
              <div [ngClass]="i !=0 ? ' ' : ''" class="p-0 d-flex is-align-items-center">
                <img class="image is-24x24 garage-facility"  src="{{item.logo_url}}" />
                <div>
                  <p class="text-size-14 pl-1 pt-1 line-height">{{ item.name }}</p>
                </div>
              </div>
            </div>
            <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateDashboardItem?.amenities?.length" (click)="amenitiesLength = estimateDashboardItem?.amenities?.length">+{{estimateDashboardItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
            <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.amenities?.length === amenitiesLength && estimateDashboardItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
          </div>
          <div class="features-wrapper columns is-multiline pt-1 pl-3 my-0 mr-0 mobile-amenities">
            <div class="features-inner pb-1"
              *ngFor="let item of estimateDashboardItem?.specialization?.slice(0, specializationLength); index as i">
              <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                  <p class="text-size-13 px-2 py-1">{{ item.name }}</p>
              </div>
            </div>
            <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.specialization?.length > defaultSpecializationCount && specializationLength !== estimateDashboardItem?.specialization?.length" (click)="specializationLength = estimateDashboardItem?.specialization?.length">+{{estimateDashboardItem?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
            <div class="is-clickable more pt-1" *ngIf="estimateDashboardItem?.specialization?.length === specializationLength && estimateDashboardItem?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}</div>
          </div>
          <div class="columns upcoming-app-details px-3 is-multiline mt-0 pt-2">
            <div class="column is-6 is-6-mobile p-0 mb-2 esti-vechile is-flex is-align-items-center">
              <div class="image is-flex is-justify-content-center is-align-items-center" >
                <img src="/assets/svg/veh-info.svg" alt="">
              </div>
              <div class="ml-4">
                <h4>{{'DASHBOARD.VEHICLE' | translate}}</h4>
                <p>{{estimateDashboardItem.vehcileName}}</p>
              </div>
            </div>
            <div *ngIf="statusMain == 3 || statusMain == 1 || statusMain == 4" class="column is-6 is-6-mobile p-0 mb-2 esti-location is-flex is-align-items-center">
              <div class="image is-flex is-justify-content-center is-align-items-center" >
                <img class="icon is-medium" src="../../../assets/svg/location-final.svg" alt="">
              </div>
              <div class="ml-4">
                <h4>{{'HOME.LOCATION' | translate}}</h4>
                <p style="word-break: break-all;">{{estimateDashboardItem.zip_code}}</p>
              </div>
            </div>
            <div class="column is-6 is-6-mobile p-0 mb-2 esti-services is-flex is-align-items-center">
              <div class="image is-flex is-justify-content-center is-align-items-center" >
                <img src="/assets/svg/service_detail.svg" alt="">
              </div>
              <div class="ml-4">
                <h4>{{'DASHBOARD.SERVICES' | translate}}</h4>
                <ul *ngFor="let item of estimateDashboardItem.items; let i = index">
                  <li style="cursor: pointer;" (click)="toggleDetails(i,item)" class="mb-0 pb-1">{{i+1}}. {{item.name}}
                    <img *ngIf="selectedItemIndex !== i && (item.selected_answer || item.notes)" class="pl-1" src="../../../assets/svg/VectorUp.svg" alt="" width="12px" height="12px">
                    <img *ngIf="selectedItemIndex === i && (item.selected_answer || item.notes)" class="pl-1" src="../../../assets/svg/Vectordown.svg" alt="" width="12px" height="12px">
                  </li>
                  
                  <div *ngIf="selectedItemIndex === i" class="pl-4 detaileddata">
                    <div *ngFor="let ans of Data" class="is-flex">
                      <i class="fa fa-solid fa-circle custom-icon"></i>
                      <div class="pl-2">{{ans.relevant_answer}} <span *ngIf="ans.relevant_answer">-</span> {{ans.selected_answer}}</div>
                    </div>
                    <div *ngIf="item.notes" class="pb-1" >
                      <!-- <p style="color: #aad043; font-weight: bold;">Comments:</p> -->
                      <div *ngIf="item.notes.length < 25" class="comments">
                          {{item.notes}}
                      </div>
                      <div *ngIf="item.notes.length > 25 && item.showMore" class="comments">
                          {{item.notes.slice(0, 25)}} <span class="is-clickable more" (click)="item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                      </div>
                      <div *ngIf="item.notes.length > 25 && !item.showMore" class="comments">
                          {{item.notes}} <span class="is-clickable more" (click)="item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                      </div>
                  </div>
                  </div>
                  
                </ul>
              </div>
            </div>
            <div *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null" class="column is-6 is-6-mobile p-0 mb-2 app-premium is-flex is-align-items-center">
              <div class="image is-flex is-justify-content-center is-align-items-center" >
                <img src="/assets/svg/currency.svg" alt="">
              </div>
              <div class="ml-4">
                <h4 class="pb-1">{{'DASHBOARD.ESTIMATION_PRICE' | translate}}</h4>
                  <p class="has-text-weight-medium text-size-14 mr-3 diagonal-strikethrough mb-1" *ngIf="estimateDashboardItem.total !== estimateDashboardItem.original_price"> {{estimateDashboardItem.original_price}}</p>
                  <p class="has-text-weight-bold text-size-22">{{estimateDashboardItem.total}}</p>
              </div>
            </div>
            <div *ngIf="statusMain == 3" class="date is-flex py-2">
              <div class="image is-flex is-justify-content-center is-align-items-center" >
                <img src="/assets/svg/date-icon.svg" alt="">
              </div>
              <div class="is-flex">
                <div class="mx-4">
                  <h4>{{'DASHBOARD.CREATED_DATE' | translate}}</h4>
                  <p>{{estimateDashboardItem.estimate_date}}</p>
                </div>
                <div class="ml-4">
                  <h4>{{'DASHBOARD.EXPIRY_DATE' | translate}}</h4>
                  <p>{{estimateDashboardItem.estimate_expiry}}</p>
                </div>
              </div> 
            </div>


          </div>
        </div>
        <div class="column is-3-desktop is-4-tablet pr-0 has-text-centered is-flex is-flex-direction-column is-justify-content-center">

          <!-- <div class="estimates-no" *ngIf="statusMain == 2">
            <p class="has-text-weight-bold text-size-22">{{estimateDashboardItem.garage_count}}</p>
            <p>{{'DASHBOARD.ESTIMATE' | translate}}</p>
          </div> -->
          <a class="button p-0 is-success has-text-weight-bold" (click)="viewDetails()" *ngIf="statusMain == 2">
            {{'DASHBOARD.VIEW_OFFER'|translate}}
          </a>
          <a class="mt-4 button-secondary" (click)="openCancelPopup('1')" *ngIf="statusMain == 2">
            {{'DASHBOARD.CANCEL' | translate}}
          </a>
          <div class="has-text-centered is-flex is-align-items-center is-flex-direction-column">
            <!-- <div class="is-hidden" *ngIf="statusMain == 1">
 <p class="has-text-weight-bold text-size-22">200</p>
 </div> -->
            <div class="appoint-wrapper mb-3" *ngIf="statusMain != 2">
              <!-- <div class="mt-2" *ngIf="statusMain == 1">
 <div class="up-appointment mb-4">
 <div>
 <h4 class="has-text-left line-height mb-1">Randevu</h4>
 <p class="has-text-left line-height">{{estimateDashboardItem.updateDate}}</p>
 </div>
 </div>
 <div class="up-timing">
 <div>
 <h4 class="has-text-left line-height mb-1">Zaman</h4>
 <p class="has-text-left line-height">{{estimateDashboardItem.updateTime}}</p>
 </div>
 </div>
 </div> -->
              <div class="has-text-centered" *ngIf="statusMain == 4">
                <div class="up-appointment">
                  <div *ngIf="!estimateDashboardItem.appointment1">
                    <h4 class="has-text-left line-height mb-1">Expired date</h4>
                    <p class="has-text-left line-height">
                      {{estimateDashboardItem.expiredFormatDate}}
                    </p>

                  </div>
                  <div *ngIf="estimateDashboardItem.appointment1 && estimateDashboardItem.updated_by !== 'garage'">
                    <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.BOOKED_ON' | translate}}</h4>
                    <p class="has-text-left line-height pb-1">
                      {{estimateDashboardItem.appointmentDate}}
                    </p>
                    <p class="has-text-left line-height pb-1">
                      {{estimateDashboardItem.appointmentTime}}
                    </p>
                  </div>
                  <div *ngIf="estimateDashboardItem.appointment1 && estimateDashboardItem.updated_by == 'garage'">
                    <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.APPOINTMENT_TIME_PASSED' | translate}}</h4>
                    <p class="has-text-left line-height pb-1">
                      {{estimateDashboardItem.appointmentDate}}
                    </p>
                    <p class="has-text-left line-height pb-1">
                      {{estimateDashboardItem.appointmentTime}}
                    </p>
                  </div>
                </div>

              </div>
              <div class="has-text-centered" *ngIf="statusMain == 3">
                <div class="up-appointment">
                  <div *ngIf="!estimateDashboardItem.appointment1">
                    <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.CANCELLED' | translate}}</h4>
                    <p class="has-text-left line-height">
                      {{estimateDashboardItem.cancelledFormatDate}}
                    </p>
                  </div>
                  <div *ngIf="estimateDashboardItem.appointment1">
                    <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.BOOKED_ON' | translate}}</h4>
                    <p class="has-text-left line-height pb-1">
                      {{estimateDashboardItem.appointmentDate}}
                    </p>
                    <p class="has-text-left line-height pb-1">
                      {{estimateDashboardItem.appointmentTime}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="has-text-centered" *ngIf="statusMain == 1">
                <div class="up-appointment mb-4">
                  <div>
                    <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.BOOKED_ON' | translate}}</h4>
                    <p class="has-text-left line-height">
                      {{estimateDashboardItem.updateDate}}
                    </p>
                  </div>
                </div>
                <div class="up-timing">
                  <div>
                    <h4 class="has-text-left line-height mb-1">{{'DASHBOARD.TIME' | translate}}</h4>
                    <p class="has-text-left line-height">{{estimateDashboardItem.updateTime}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100">
            <button class="button-secondary mb-2" *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null && statusMain != 3 && statusMain != 4"
              (click)="editBooking(estimateDashboardItem)">{{'DASHBOARD.RESCHEDULE' | translate}}</button>
            <button class="button-secondary mb-2" (click)="viewQuotation()"
              *ngIf="statusMain != 2 && estimateDashboardItem.logo !== null && statusMain != 3 && statusMain != 4">{{'DASHBOARD.VIEW_QUOTATIONS' | translate}}</button>
            <button class="button-secondary" (click)="openCancelPopup('')"
              *ngIf="statusMain != 2 && statusMain != 3 && statusMain != 4 && estimateDashboardItem.logo !== null">{{'PROFILE.CANCEL' | translate}}</button>
              <button class="button-secondary" (click)="requestQuote(estimateDashboardItem)"
              *ngIf="statusMain == 3 || statusMain == 4 && estimateDashboardItem.logo !== null">{{'DASHBOARD.REQUEST_NEW_QUOTE' | translate}}</button>
          </div>
        </div>

      </div>
    </mat-expansion-panel>
  </div>
</div>
<app-message *ngIf="show_message" [estimation]="estimateDashboardItem.garage[0]" [quoteId]="estimateDashboardItem.id"
  (hideMessage)="hideMessagePopup($event)"></app-message>

  <div *ngIf="show_cancel" class="modal fade is-active">
    <div class="modal-card">
      <div class="modal-card-body py-5 is-flex is-align-items-center is-justify-content-center">
        <div>
          <div *ngIf="!estimation_cancel" class="has-text-centered pb-4 has-text-weight-bold">
            {{'DASHBOARD.WOULD_YOU_LIKE_TO_CANCEL_THIS_JOB' | translate}}
            <!-- <span *ngIf="['estimate', 'Awaiting'].includes(estimateDashboardItem.status)">Estimation</span>
            <span *ngIf="['booked', 'cancelled'].includes(estimateDashboardItem.status)">Job</span>
            ? -->
          </div>
          <div *ngIf="estimation_cancel" class="has-text-centered pb-4 has-text-weight-bold">
            {{'DASHBOARD.WOULD_YOU_LIKE_TO_CANCEL_THIS_ESTIMATE' | translate}}
          </div>
          
          <div class="has-text-centered">
            <div class="button btn_cancel has-text-weight-semibold m-2" (click)="show_cancel = false">
              {{'DASHBOARD.NO' | translate}}
            </div>
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="cancelEstimate()">
              {{'COMMON.YES' | translate}}
            </div>
          </div>
        </div>
        
      </div>
      </div>
    </div>

    <div *ngIf="show_popup" class="modal fade is-active">
      <div class="modal-card">
        <div class="modal-card-body py-5  is-flex is-align-items-center is-justify-content-center">
          <div>
            <div class="has-text-centered pb-4 has-text-weight-bold">
              {{'DASHBOARD.WOULD_YOU_LIKE_TO_REQUEST_NEW_QUOTE' | translate}}
            </div>
            
            <div class="has-text-centered">
              <div class="button btn_cancel has-text-weight-semibold m-2" (click)="show_popup = false">
                {{'DASHBOARD.NO' | translate}}
              </div>
              <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="viewDetails()">
                {{'COMMON.YES' | translate}}
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  
