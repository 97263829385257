import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { Utils } from 'src/app/common/utils';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-service-request-layout',
  templateUrl: './service-request-layout.component.html',
  styleUrls: ['./service-request-layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({  transform: 'translateY(-100%)' }), // Start invisible and off-screen
        animate('0.7s ease-in-out', style({ transform: 'translateY(0%)' })), // Slide in and become visible
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({  transform: 'translateY(-100%)' })), // Slide out and become invisible
      ]),
    ]),
    trigger('slideRouter', [
      transition(':enter', [
        style({  transform: 'translateY(-70px)' }), // Start invisible and off-screen
        animate('0.7s ease-in-out', style({ transform: 'translateY(0px)' })), // Slide in and become visible
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({  transform: 'translateY(-70px)' })), // Slide out and become invisible
      ]),
    ]),
      trigger('moveUp', [
        state('initial', style({
          transform: 'translateY(0)'
        })),
        state('moved70', style({
          transform: 'translateY(-70px)'
        })),
        state('moved125', style({
          transform: 'translateY(-125px)'
        })),
        transition('initial => moved70', [
          animate('0.5s ease-in-out')
        ]),
        transition('moved70 => initial', [
          animate('0.5s ease-in-out')
        ]),
        transition('initial => moved125', [
          animate('0.5s ease-in-out')
        ]),
        transition('moved125 => initial', [
          animate('0.5s ease-in-out')
        ])
      ])
    ]
})
export class ServiceRequestLayoutComponent implements OnInit {
  @Input() FromLocation: boolean = false;
  @Input() isShowingMap: boolean = false;
  @ViewChild('leftColumn') leftColumn!: ElementRef;
  @ViewChild('marTopMobile') marTopMobile!: ElementRef;

  isStep = 1; stepperCount = 1;
  isVisibleBackButton: boolean = false;
  backText: string = '';
  pageRoute: string = '';
  isBackButton: boolean = true;
  isShowingSaveEstimate: boolean = false
  isShowingSavingText: boolean = true
  userData: any;
  show_popup:boolean = false;
  reschedule : boolean = false;
  serviceList: any;
  vehicleDetails: any;
  estimateData: any;
  showCartPopup: boolean = false;
  deactivateBtn: boolean = false;
  showEditPopup: boolean = false
  inComments: boolean = false
  hideForward: boolean = false
  hideHeader: boolean = false
  moveState: string = 'initial';
  isTablet!: boolean;
  showChatWithGaragePopup: boolean = false;
  showMessagePopup: boolean = false;
  messagePopupData: any;
  showVehicleChangedPopup: boolean = false;
  showSaveServicePopup: boolean = false;
  isEstimateRoute: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();
  constructor(private sharedService: SharedServiceService, private activatedRoute: ActivatedRoute,
    private route: Router, private translate: TranslateService,
    private utils: Utils,
    private passService: PassDataService, private elementRef: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(history.state != undefined){
      if (history.state.reschedule) {
        this.reschedule = history.state.reschedule
      }
    }
    this.userData = localStorage.getItem(Constants.APP.SESSION_USER_DATA) 
    this.sharedService.getData().pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.fetchServiceDetails()
      this.pageRoute = data
      this.isBackButton = true
      this.stepperCount = Number(data);
      if (Number(data) === 2) {
        this.isVisibleBackButton = true
        this.translate.get("SERVICE.TEXT_19").subscribe((data) => {
          this.backText = data
        })
      } else if (Number(data) === 3) {
        this.isVisibleBackButton = true
        this.isBackButton = false
        this.translate.get("SERVICE.TEXT_20").subscribe((data) => {
          this.backText = data
        })
      } else if (Number(data) === 4) {
        this.isVisibleBackButton = true
        this.isBackButton = true
        this.translate.get("SERVICE.TEXT_21").subscribe((data) => {
          this.backText = data
        })
      } else if (Number(data) === 5) {
        this.isVisibleBackButton = true
        this.isBackButton = true
        this.translate.get("SERVICE.TEXT_25").subscribe((data) => {
          this.backText = data
        })
      }
    })
    this.sharedService.getSubData().pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.isBackButton = true
      this.pageRoute = data
      if (data === '1.1') {
        this.isVisibleBackButton = true
        this.translate.get("HOME.TEXT_71").subscribe((data) => {
          this.backText = data
        })
      } else if (data === '1.2') {
        this.isVisibleBackButton = true
        this.translate.get("SERVICE.TEXT_35").subscribe((data) => {
          this.backText = data
        })
      }
    })
    this.passService.getVehicleData().pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.fetchServiceDetails()
    })

    this.passService.getMapView().pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.isShowingMap = data
      if (!this.isShowingMap) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        let el = document.getElementById('mapLoad');
        if (el != undefined) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    })
    this.sharedService.isCommentsPage$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if(this.vehicleDetails){
        this.inComments = true
      }
    })
    this.sharedService.triggerPopup$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      switch(data){
        case 'chatWithGarage':
          this.showChatWithGaragePopup = true
          break;
        case 'messagePopup':
          this.showMessagePopup = true
          break;
        case 'vehicleChanged':
          this.showVehicleChangedPopup = true
          break;
        case 'saveService':
          this.showSaveServicePopup = true
          break;
      }
    })
    this.sharedService.messagePopupData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.messagePopupData = data
    })

    let quoteId = sessionStorage.getItem(Constants.APP.QUOTE_ID)
    if (quoteId != undefined) {
      this.isShowingSavingText = false
    }
    this.passService.getUpdateFromEstimate().pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.isShowingSavingText = data
    })
    this.fetchServiceDetails()
    this.sharedService.showAddService$.subscribe((data:boolean)=>{
      this.hideForward = data
    })
    this.isTablet = window.innerWidth < 1024;
    window.addEventListener('resize', () => {
      this.isTablet = window.innerWidth < 1024;
    });
 
  
  }
  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      const leftColumn = this.leftColumn.nativeElement;
      leftColumn.scrollTop += 40; // Scroll down by 40px (adjust as needed)
      event.preventDefault(); // Prevent the default page scroll behavior
    } else if (event.key === 'ArrowUp') {
      const leftColumn = this.leftColumn.nativeElement;
      leftColumn.scrollTop -= 40; // Scroll up by 40px (adjust as needed)
      event.preventDefault(); // Prevent the default page scroll behavior
    }
  }
 
  ngAfterViewChecked(){
    if(!this.FromLocation){
      if(window.innerWidth < 768){
        this.leftColumn.nativeElement.style.maxHeight = 100 + '%';
      } else {
        const marTopMobileHeight = this.marTopMobile.nativeElement.offsetHeight;
        this.leftColumn.nativeElement.style.maxHeight = marTopMobileHeight + 'px';
      }
    }
    // if(this.stepperCount !== 3){
    //   this.moveState = 'initial'
    //   this.sharedService.setEstimateScrolling(false)
    //   document.body.classList.add('inner-stepper');
    // }
  }
  onActivate(component: any): void {
    // Check if the activated component is ServiceEstimateComponent
    this.isEstimateRoute = component.constructor.name === 'ServiceRequestEstimatesComponent';
  }

  onDeactivate(): void {
    // Reset the value when a component is deactivated
    this.isEstimateRoute = false;
  }
  triggerMove(data: string) {
    if(this.stepperCount === 3){
      this.moveState = data
    }
  }
  hideMessagePopup(data: any){
    this.showMessagePopup = false
    this.sharedService.setPopupResponse('hideMessage')
  }
  nextStepper(){
    this.showVehicleChangedPopup = false
    this.sharedService.setPopupResponse('vehicleChanged')
  }
  saveEstimatePopup(){
    this.sharedService.setPopupResponse('chatWithGarage')
  }
  skipService(){
    this.sharedService.setPopupResponse('skipService')
  }
  addAnotherService(){
    this.sharedService.setPopupResponse('addAnotherService')
  }
  passMapView() {
    // this.isShowingMap = false
    // this.passService.setChangeMapText(false)
  }
  fetchServiceDetails(){
    this.getLocalServiceList()
    this.vehicleDetails = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
    this.estimateData = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
  }
  getLocalServiceList(){
    this.serviceList = JSON.parse(localStorage.getItem(Constants.APP.SERVICE_LIST) || '[]')
    this.serviceList = this.serviceList.filter((item:any)=>item.answer.id)
    this.serviceList?.forEach((item: any) => {
      if(typeof item.selected_answer == 'string')
      item.selected_answer = JSON.parse(item.selected_answer)
    })
   if(this.serviceList?.length > 0){
    const res = this.serviceList.filter((i:any) => !i.question.fromMaster)
    this.serviceList = res
   }
  }
  onForwardPressed(){
    if(this.inComments){
      this.inComments = false
      // this.sharedService.triggerForwardBtn()
    }
    if(this.stepperCount == 1){
      this.route.navigate(['/service/vehicle']);
    }else if(this.stepperCount == 2){
      this.sharedService.triggerForwardBtn()
      // this.route.navigate(['/service/estimates']);
    }else if(this.stepperCount == 3){
      this.route.navigate(['/service/book']);
    }
    this.fetchServiceDetails()
  }

  nextStep() { this.isStep++; }

  previousStep() { this.isStep--; }

  onClickClose() {
    if(this.userData == undefined && this.stepperCount == 3){
      this.show_popup = true
    }else{
      localStorage.removeItem(Constants.APP.SERVICE_LIST)
      localStorage.removeItem("address")
      localStorage.removeItem("zipcode")
      // sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
      sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
      sessionStorage.removeItem(Constants.APP.QUOTE_ID)
      this.sharedService.clearData()
      document.body.classList.remove('inner-stepper');
      if (this.userData != undefined) {
        this.route.navigate(['/dashboard'])
      } else {
        this.route.navigate(['/home'])
      }
    }
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const scrollTop = (event.target as HTMLElement).scrollTop;
    this.hideHeader = scrollTop > 0 ? true : false;
    if(this.hideHeader){
      if(this.isTablet){
        this.triggerMove('moved125')
      }else{
        this.triggerMove('moved70')
      }
      this.sharedService.setEstimateScrolling(true)
      document.body.classList.remove('inner-stepper');
    }else {
      this.triggerMove('initial')
      this.sharedService.setEstimateScrolling(false)
      document.body.classList.add('inner-stepper');
    }
  }
  onBackPressed() {
    this.deactivateBtn = true
    this.inComments = false
    setTimeout(() => {
      this.deactivateBtn = false
    }, 250);
    if(this.stepperCount === 4){
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    }
    if (this.pageRoute === '1.2'){
      this.passService.setBackPressed(this.pageRoute)
    }else if(parseFloat(this.pageRoute) < 2){
      this.passService.setBackPressed(this.pageRoute)
      this.pageRoute = ( parseFloat(this.pageRoute) - 0.1).toFixed(1)
    } else {
      if(this.stepperCount === 2){
        this.sharedService.updateShowingAddService(true)
      }
      if(this.stepperCount === 3){
        this.showEditPopup = true
      }else{
        this.sharedService.setEndServiceData(null)
        this.sharedService.setProblemData(null)
        this.sharedService.setPopularServiceData(null)
        this.passService.setBackPressed(this.pageRoute)
      }
    }
  }

  cancelRequest() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.route.navigate(['/dashboard'])
    } else {
      this.route.navigate(['/home'])
    }
  }

  saveEstimate(data: string) {
    if(data != 'manual save estimation'){
      this.passService.setSaveEstimateListener(data)
    }
    if (this.userData != undefined) {
      // this.route.navigate(['/dashboard'])
    } else {
      this.route.navigate(['/auth'], { state: { isFromEstimateValue: true } })
    }
  }
  userLogin(){
    this.route.navigate(['/auth'], { state: { isFromVechile: true } })
  }
  help(){
    this.route.navigate(['/help']) 
  }
  clickNext(){
    let serviceList = JSON.parse(localStorage.getItem(Constants.APP.SERVICE_LIST) || '[]')
    if(serviceList.length > 0){
        this.route.navigate(['/service/vehicle']);
    }else{
      this.utils.openErrorSnackBar('DASHBOARD.PLEASE_SELECT_SERVICE')
    }
  }
  showQuoteDetail(){
    this.showCartPopup = true
    // this.route.navigate(['job-details'], {state: {stepperCount : this.stepperCount}})
  }
  continueEdit(){
        sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
        this.showEditPopup = false
        this.sharedService.setEndServiceData(null)
        this.sharedService.setProblemData(null)
        this.sharedService.setPopularServiceData(null)
        this.passService.setBackPressed(this.pageRoute)
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
      const ClickedElement = event.target as HTMLElement 
      const alterationCard = ClickedElement.closest('.alteration-card');
      const alterationModal = ClickedElement.closest('.alteration-modal');
      if (!alterationCard && alterationModal) {
        this.showEditPopup = false
      }   
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
