import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Utils } from 'src/app/common/utils';
import { Constants } from 'src/app/Constants/constants';
import * as moment from 'moment';
import { BookingServiceService } from 'src/app/services/booking-service.service';

@Component({
  selector: 'app-service-request-calender-line-item',
  templateUrl: './service-request-calender-line-item.component.html',
  styleUrls: ['./service-request-calender-line-item.component.scss']
})
export class ServiceRequestCalenderLineItemComponent implements OnInit {
  @ViewChild('dateList') dateList: any = ElementRef;
  @ViewChild('dateListMobile') dateListMobile: any = ElementRef;
  @Input() step: any;
  @Input() name: any;
  currentStep = 1;
  view: boolean = true;
  dateArrayList: any = [];
  formData = [{
    dates: 0,
    time: 0
  }, {
    dates: 0,
    time: 0
  }, {
    dates: 0,
    time: 0
  }]

  timingData: any = [];
  estimateTimingDetails: any;
  lastDate:any;
  @Output() timeClickEvent = new EventEmitter<any>();
  @Output() monthClickEvent = new EventEmitter<any>();
  @Output() onClickViewCalendar: EventEmitter<any> = new EventEmitter<any>();
  isLoading: boolean = false;
  constructor(private router: Router,
    private sharedService: SharedServiceService,
    private utilsClass: Utils,
    private translate: TranslateService,
    private bookingservice: BookingServiceService) { }

  ngOnInit() {
    this.estimateTimingDetails = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    this.estimateTimingDetails = JSON.parse(this.estimateTimingDetails)
    // console.log(this.estimateTimingDetails)


    // this.sharedService.getEstimateData().subscribe((data:any)=>{
    //   if(data != null){
    //     this.estimateTimingDetails = data
    //     console.log(this.estimateTimingDetails);
    //   }
    // })

    this.getNext10Days()

  }


  scrollToLeft() {
    this.dateList.nativeElement.scrollLeft -= 150;
    this.dateListMobile.nativeElement.scrollLeft -= 150;
  }

  scrollToRight() {
    this.dateList.nativeElement.scrollLeft += 150;
    this.dateListMobile.nativeElement.scrollLeft += 150;
  }

  confirmPage() {
    this.sharedService.updateData('5');
    // sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_TIME)
    this.router.navigate(['/service/confirm'])
  }

  // stepclick() {
  //   this.view = !this.view;

  // }

  setStep(step: any) {
    this.currentStep = step;
  }

  updateDate(item: any) {
    // this.monthClickEvent.emit()
    this.isLoading = true
    this.dateArrayList.forEach((element: any) => {
      element.calIndex = this.name.id
      if (item.id == element.id) {
        element.selected = true
        element.datePick = false
        this.monthClickEvent.emit(element);
      } else {
        element.selected = false
      }

    });
    this.getTimeList(true)
  }

  getTimeList(clickDate: boolean) {
    let job_allowed_timigs = this.estimateTimingDetails.jobAllowedTimings;
    this.dateArrayList.forEach((element: any) => {
      if (element.selected) {
        this.timingData = [];
        let index = 0;
        let expectedTime = moment(element.start_time, 'HH:mm').add(index, 'hours');
        while (moment(element.end_time, 'HH:mm').isAfter(moment(expectedTime, 'HH:mm'))) {
          let time = moment(expectedTime, "HH:mm").format("HH:mm")
          let timeData = {
            time: time,
            isSelected: false,
            day_of_week: element.day_of_week,
            date: element.exact_date
          }
          let dateTime = element.exact_date + ' ' + time
          if (!moment().isAfter(moment(dateTime, 'DD/MM/YYYY HH:mm'))) {
            this.timingData.push(timeData)
          }
          index++
          expectedTime = moment(element.start_time, 'HH:mm').add(index, 'hours');
        }
      }
    });

    if (job_allowed_timigs.length != 0) {
      let blocked_slot = false;
      let temp_timing_data: any = [];
      job_allowed_timigs.forEach((item: any) => {
        this.timingData.forEach((element: any) => {
          if (item.day_of_week == (element.day_of_week == 0 ? 7 : element.day_of_week)) {
            JSON.parse(item.hour).forEach((ele: any) => {
              if (ele == element.time)
                temp_timing_data.push(element)
            });
            blocked_slot = true;
          }
        });
      });

      this.timingData = temp_timing_data
      if (!blocked_slot)
        this.timingData = []
    }
    let temp_timingData: any = this.timingData;

    this.timingData.forEach((element: any, index: any, array: any) => {
      let data = {
        'date_time': element.date + ' ' + element.time,
        'garage_id': this.estimateTimingDetails.id
      }
      this.bookingservice.fetchJobTimings(data).subscribe({
        next: (res: any) => {
          res.data.forEach((item2: any) => {
            if (item2.count >= job_allowed_timigs[0]?.max_jobs_per_hr) {
              temp_timingData = temp_timingData.filter((ele: any) => ele.time !== element.time)
            }
            this.timingData = temp_timingData
          })
          if(array.length -1 === index){
            this.isLoading = false
            this.filterTime()
          }    
        }, error: (err: any) => {
          console.log(err)
        }
      });
    });

   
    if (clickDate) {
      if (this.timingData.length === 0) {
        this.isLoading= false
        this.utilsClass.openErrorSnackBar(this.translate.instant('COMMON.NO_TIME_SELECTED'))
      }
    }
  }

  filterTime(){
    if(this.name.id == 2){
      let first_appointment:any = JSON.parse(sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_TIME) || '')
      let temp = this.timingData.filter((item:any)=> item.date == first_appointment[0].exact_date && item.time > first_appointment[0].selectedTime)
      if(temp.length > 0)
      this.timingData = temp
    }
  }
  updateTime(pos: any, time: number) {
    let sendTimeDate = null;
    this.dateArrayList.forEach((element: any) => {
      if (element.selected) {
        element.selectedTime = this.timingData[time].time
        element.selected_id = this.name
        sendTimeDate = element
        // this.timingData[time].isSelected = this.timingData[time].isSelected ? false : true
      }
    });
    this.timingData.forEach((element: any, index: number) => {
      if (index === time) {
        if (element.isSelected) {
          element.isSelected = this.timingData[time].isSelected ? true : false
        } else {
          element.isSelected = this.timingData[time].isSelected ? false : true
        }
      } else {
        element.isSelected = false
      }
    })
    if(this.name.id == 1){
    this.lastDate =  this.timingData.slice(-1)[0]
    if(this.lastDate!=undefined){
      sessionStorage.setItem(Constants.APP.LAST_INDEX_TIME,JSON.stringify(this.lastDate))
    }
  }
    this.timeClickEvent.emit(sendTimeDate)
    this.formData[pos].time = time;
    if(this.name.is == 2){
      sessionStorage.removeItem(Constants.APP.LAST_INDEX_TIME)
    }
  }

  getNext10Days() {
    const months = this.utilsClass.getMonthList(this.utilsClass.getCurrentLanguage().toLowerCase(), 'short');
    const weekday = this.utilsClass.getWeekList();
    const timings = this.estimateTimingDetails.timings;
    for (let index = 1; index <= this.utilsClass.getMaxEstimateDays(); index++) {
      var targetDate = new Date();
      targetDate.setDate(targetDate.getDate() + index);
      timings?.forEach((element: any) => {
        if (element.start_time != null && element.end_time != null && targetDate.getDay() == (element.day_of_week == 7 ? 0 : element.day_of_week) && element.start_time != '') {
          let date = {
            id: index,
            date: targetDate.getDate(),
            month: months[targetDate.getMonth()],
            day: weekday[targetDate.getDay()],
            day_of_week: targetDate.getDay(),
            start_time: element.start_time,
            end_time: element.end_time,
            selected: false,
            exact_date: moment(targetDate).format('DD/MM/YYYY'),
            timingsList: [],
          }
          this.dateArrayList.push(date)
        }
      });
    }
    let current_time = moment()
    let reached_time = moment('15:00','HH:mm')
    if(current_time.isAfter(reached_time)){
        this.dateArrayList.shift()
    }
    this.getTimeList(false)
  
    if(this.name.id == 2){
      let last_date:any
      if(sessionStorage.getItem(Constants.APP.LAST_INDEX_TIME)){
        last_date = JSON.parse(sessionStorage.getItem(Constants.APP.LAST_INDEX_TIME) || '')
      }
      let first_appointment:any = JSON.parse(sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_TIME) || '')
      // let tempDateArrayList = this.dateArrayList.filter((item:any)=>item.id>=first_appointment[0].id )
      // this.dateArrayList = tempDateArrayList
      let tempDateArrayList = this.dateArrayList.filter((item:any) => {
        let selectedTime = moment(first_appointment[0].selectedTime, 'HH:mm');
        let endTime = moment(last_date.time, 'HH:mm');
        return item.id >= first_appointment[0].id && !(endTime.isSame(selectedTime) && item.exact_date === first_appointment[0].exact_date);
    });
          this.dateArrayList = tempDateArrayList
    }
  }

  clickViewCalendar() {
    this.onClickViewCalendar.emit()
  }

}
